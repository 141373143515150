import React, { useEffect, useState } from "react";
import { Box, Text } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";
import { useSwipeable } from "react-swipeable";
import {
  MessageStatus,
  ReduxMessage,
} from "../../store/reducers/messageListReducer";
import SendingIcon from "../icons/Sending";
import DeliveredIcon from "../icons/Delivered";
import ReadIcon from "../icons/Read";
import FailedIcon from "../icons/Failed";
import BellMuted from "../icons/BellMuted";
import { NOTIFICATION_LEVEL } from "../../constants";
import { SetSidType, SetUnreadMessagesType } from "../../types";
import { getMessageStatus } from "../../api";
import { getLastMessageTime } from "./../../utils/timestampUtils";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import ArchiveIcon from '@mui/icons-material/Archive';
interface SingleConvoProps {
  convoId: string;
  setSid: SetSidType;
  currentConvoSid: string;
  lastMessage: string;
  myMessage: ReduxMessage | false;
  unreadMessagesCount: number;
  convo: ReduxConversation;
  updateUnreadMessages: SetUnreadMessagesType;
  onClick: () => void;
  participants: ReduxParticipant[];
  messages: ReduxMessage[];
  typingInfo: string[];
  use24hTimeFormat: boolean;
  onArchive: (convo: ReduxConversation) => void;
}

const measureWidth = (text: string): number => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    return 0;
  }
  context.font = "bold 14px Inter";
  return context.measureText(text).width;
};

function calculateUnreadMessagesWidth(count: number) {
  if (count === 0 || !count) {
    return 0;
  }
  return measureWidth(count.toString()) + 32;
}

function truncateMiddle(text: string, countWidth: number) {
  const width = measureWidth(text);
  if (width > 288 - countWidth) {
    const textLength = text.length;
    const avgLetterSize = width / textLength;
    const nrOfLetters = (288 - countWidth) / avgLetterSize;
    const delEachSide = (textLength - nrOfLetters + 1) / 2;
    const endLeft = Math.floor(textLength / 2 - delEachSide);
    const startRight = Math.ceil(textLength / 2 + delEachSide);
    return text.substr(0, endLeft) + "..." + text.substr(startRight);
  }
  return text;
}

const ConversationView: React.FC<SingleConvoProps> = (props: SingleConvoProps) => {
  const {
    convo,
    convoId,
    myMessage,
    lastMessage,
    unreadMessagesCount,
    use24hTimeFormat,
    onArchive,
  } = props;
  const [backgroundColor, setBackgroundColor] = useState<string>();
  const title = truncateMiddle(
    convo.friendlyName ?? convo.sid,
    calculateUnreadMessagesWidth(unreadMessagesCount)
  );
  const theme = useTheme();
  const textColor =
    unreadMessagesCount > 0
      ? theme.textColors.colorText
      : theme.textColors.colorTextIcon;
  const muted = convo.notificationLevel === NOTIFICATION_LEVEL.MUTED;

  const [lastMsgStatus, setLastMsgStatus] = useState<string>("");
  const time = getLastMessageTime(props.messages, use24hTimeFormat);

  const [translateX, setTranslateX] = useState(0);

  useEffect(() => {
    if (props.currentConvoSid === convo.sid) {
      setBackgroundColor(theme.backgroundColors.colorBackgroundStrong);
      return;
    }
    setBackgroundColor(theme.backgroundColors.colorBackgroundRowStriped);
  }, [props.currentConvoSid, convo.sid]);

  useEffect(() => {
    if (myMessage && !props.typingInfo.length) {
      getMessageStatus(myMessage, props.participants).then((statuses) => {
        if (statuses[MessageStatus.Read]) {
          setLastMsgStatus(MessageStatus.Read);
          return;
        }
        if (statuses[MessageStatus.Delivered]) {
          setLastMsgStatus(MessageStatus.Delivered);
          return;
        }
        if (statuses[MessageStatus.Failed]) {
          setLastMsgStatus(MessageStatus.Failed);
          return;
        }
        if (statuses[MessageStatus.Sending]) {
          setLastMsgStatus(MessageStatus.Sending);
          return;
        }
      });
    }
  }, [convo, myMessage, lastMessage, props.participants, props.typingInfo]);

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      if (eventData.dir === 'Left') {
        setTranslateX(Math.max(-100, eventData.deltaX));
      }
      if (eventData.dir === 'Right') {
        setTranslateX(Math.min(0, eventData.deltaX));
      }
    },
    onSwipedLeft: () => setTranslateX(-100),
    onSwipedRight: () => setTranslateX(0),
    trackMouse: true,
  });

  return (
    <Box style={{ position: "relative", overflow: "hidden" }}>
      <Box
        style={{
          position: "absolute",
          right: `${-translateX - 100}px`, // Adjust the button position proportionally with the swipe
          top: 0,
          bottom: 0,
          width: "100px",
          backgroundColor: "#0b6836",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          cursor: "pointer",
          transition: "right 0.3s ease",
          zIndex: 1,
        }}
        onClick={() => onArchive(convo)}
      >
        <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
          <ArchiveIcon/>
          <p style={{margin:"0"}}>Archive</p>
        </div>
      </Box>
      <Box
        style={{
          paddingTop: 14,
          paddingBottom: 14,
          paddingLeft: 16,
          paddingRight: 16,
          cursor: "pointer",
          backgroundColor: backgroundColor,
          position: "relative",
          transform: `translateX(${translateX}px)`,
          transition: 'transform 0.3s ease',
          zIndex: 0,
        }}
        id={convoId}
        className="name"
        onMouseOver={() => {
          if (convo.sid === props.currentConvoSid) {
            return;
          }
          setBackgroundColor(theme.backgroundColors.colorBackgroundStrong);
        }}
        onMouseOut={() => {
          if (convo.sid === props.currentConvoSid) {
            return;
          }
          setBackgroundColor(theme.backgroundColors.colorBackgroundRowStriped);
        }}
        onClick={props.onClick}
        {...handlers}
      >
        <Box
          style={{
            backgroundColor: backgroundColor,
          }}
        >
          <Box display="flex">
            <Box
              style={{
                width: 288,
                fontWeight: theme.fontWeights.fontWeightSemibold,
                fontSize: 14,
                color: muted
                  ? theme.textColors.colorTextInverseWeaker
                  : theme.textColors.colorText,
              }}
            >
              {muted ? <BellMuted /> : null}
              <span style={{ verticalAlign: "top", paddingLeft: muted ? 4 : 0 }}>
                {title}
              </span>
            </Box>
            {unreadMessagesCount > 0 && (
              <Box paddingLeft="space30">
                <Box
                  style={{
                    backgroundColor: theme.backgroundColors.colorBackgroundBrandStronger,
                    color: theme.textColors.colorTextInverse,
                    fontFamily: theme.fonts.fontFamilyText,
                    fontWeight: theme.fontWeights.fontWeightBold,
                    fontSize: theme.fontSizes.fontSize30,
                    lineHeight: theme.lineHeights.lineHeight30,
                    paddingLeft: theme.space.space30,
                    paddingRight: theme.space.space30,
                    borderRadius: 12,
                    opacity: muted ? 0.2 : 1,
                  }}
                >
                  {unreadMessagesCount}
                </Box>
              </Box>
            )}
          </Box>
          <Box
            style={{
              paddingTop: 4,
              color: textColor,
              fontWeight: theme.fontWeights.fontWeightNormal,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {!props.typingInfo.length && lastMsgStatus ? (
                <Box display="flex" paddingRight={"space20"} alignItems="center">
                  {lastMsgStatus === MessageStatus.Sending && props.myMessage && (
                    <SendingIcon />
                  )}
                  {lastMsgStatus === MessageStatus.Delivered &&
                    props.myMessage && <DeliveredIcon />}
                  {lastMsgStatus === MessageStatus.Failed && props.myMessage && (
                    <FailedIcon color="#D61F1F" />
                  )}
                  {lastMsgStatus === MessageStatus.Read && props.myMessage && (
                    <ReadIcon />
                  )}
                </Box>
              ) : null}
              <Box
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {lastMsgStatus === MessageStatus.Failed ? (
                  <Text
                    as="span"
                    color={theme.textColors.colorTextError}
                    fontWeight={theme.fontWeights.fontWeightSemibold}
                  >
                    {"Message failed to send"}
                  </Text>
                ) : (
                  <Text as="span" color={theme.textColors.colorTextWeak}>
                    {lastMessage}
                  </Text>
                )}
              </Box>
            </Box>
            <Box style={{ whiteSpace: "nowrap", paddingLeft: 4 }}>
              <Text
                as="span"
                color={theme.textColors.colorTextWeak}
                fontSize={theme.fontSizes.fontSize20}
                fontWeight={theme.fontWeights.fontWeightSemibold}
              >
                {time}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationView;
