import React, { useState } from "react";
import { Client } from "@twilio/conversations";
import { Button } from "@twilio-paste/button";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators, AppState } from "../../store";
import { getTranslation } from "../../utils/localUtils";
import AllConversationsModal from "../modals/AllConversationModal";

interface AllConversationsButtonProps {
  client?: Client;
  collapsed: boolean;
  // onClick: () => void;
}

const AllConversationsButton: React.FC<AllConversationsButtonProps> = (
  props: AllConversationsButtonProps
) => {
  const dispatch = useDispatch();
  // const { updateCurrentConversation, addNotifications, updateParticipants } =
  //   bindActionCreators(actionCreators, dispatch);
// Handle if it is Mobile  
  const isMobile = useSelector(
    (state: AppState) => state.isMobile
  );
// Handle the Drawer if it is Mobile:
const { updateDrawer } = bindActionCreators(actionCreators, dispatch)

  const local = useSelector((state: AppState) => state.local);
  const currentUserIdentity = localStorage.getItem("username") || "";
  const allConversations = getTranslation(local, "allConversations");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => {
    setIsModalOpen(true);
  }
  return (
    <>
    
      <Button fullWidth variant="secondary" onClick={handleOpen}>
        {!props.collapsed ? allConversations : null}
      </Button>
      <AllConversationsModal
        client={props.client}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        currentUserIdentity={currentUserIdentity} // Pass currentUserIdentity here
      />
    </>
  );
};

export default AllConversationsButton;
