// Import React Based Components
import React, { useState, createRef, useMemo } from "react";

// Import Redux Related Components and Packages
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import { AppState } from "../../store";

// Import Twilio related Components
import { Client } from "@twilio/conversations";

// Import Custom Components
import SettingsMenu from "./SettingsMenu";
import ManageParticipantsModal from "../modals/manageParticipantsModal";
import ManageThreadModal from "../modals/manageThreadModal";
import { Content } from "../../types";
import {
  addChatParticipant,
  removeParticipant,
} from "../../api";
import AddChatParticipantModal from "../modals/addChatMemberModal";
import ActionErrorModal from "../modals/ActionErrorModal";
import {
  getSdkConversationObject,
  getSdkParticipantObject,
} from "../../conversations-objects";

// Import Constants and Helpers
import {
  CONVERSATION_MESSAGES,
  ERROR_MODAL_MESSAGES,
} from "../../constants";
import {
  successNotification,
  unexpectedErrorNotification,
} from "../../helpers";
import { getTranslation } from "./../../utils/localUtils";

interface SettingsProps {
  participants: ReduxParticipant[];
  client?: Client;
  convo: ReduxConversation;
  isManageParticipantOpen: boolean;
  isThredHistoryOpen: boolean;
  userRole: string;
  setIsManageParticipantOpen: (open: boolean) => void;
  setIsThredHistoryOpen: (open: boolean) => void;
}

const Settings: React.FC<SettingsProps> = (props: SettingsProps) => {
  const handleParticipantClose = () => props.setIsManageParticipantOpen(false);
  const handleThreadHistoryClose = () => props.setIsThredHistoryOpen(false);


  const [isAddChatOpen, setIsAddChatOpen] = useState(false);
  const handleChatOpen = () => setIsAddChatOpen(true);
  const handleChatClose = () => setIsAddChatOpen(false);

  const [isAddSMSOpen, setIsAddSMSOpen] = useState(false);
  const handleSMSOpen = () => setIsAddSMSOpen(true);
  const handleSMSClose = () => setIsAddSMSOpen(false);

  const dispatch = useDispatch();
  const [isAddWhatsAppOpen, setIsAddWhatsAppOpen] = useState(false);
  const handleWhatsAppOpen = () => setIsAddWhatsAppOpen(true);
  const handleWhatsAppClose = () => setIsAddWhatsAppOpen(false);

  const local = useSelector((state: AppState) => state.local);
  const manageParticipants = getTranslation(local, "manageParticipants");
  const threadHistory = getTranslation(local, "threadHistory");
 
  const [names, setNames] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [inputValues, setInputValues] = useState<string[]>([]);
  const [showError, setErrorToShow] = useState<
    | {
        title: string;
        description: string;
      }
    | false
  >();
  const [errorData, setErrorData] = useState<
    | {
        message: string;
        code: number;
      }
    | undefined
  >();

  const nameInputRef = createRef<HTMLInputElement>();

  const { updateCurrentConversation, addNotifications } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const sdkConvo = useMemo(
    () => getSdkConversationObject(props.convo),
    [props.convo.sid]
  );

  function emptyData() {
    setNames([]);
    setInputValues([]);
    setErrors([]);
  }

  const handleAddParticipants = async () => {
    try {
      for (let i = 0; i < names.length; i++) {
        const name = names[i].trim();
        await addChatParticipant(name, sdkConvo, addNotifications);
      }
      emptyData();
      handleChatClose();
    } catch (e) {
      setErrorData(e.body);
      setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
    }
  };

  return (
    <>
      <SettingsMenu
        userRole={props.userRole}
        onParticipantListOpen={() => props.setIsManageParticipantOpen(true)}
        onThreadOpen={() => props.setIsThredHistoryOpen(true)}

        leaveConvo={async () => {
          try {
            await sdkConvo.leave();
            successNotification({
              message: CONVERSATION_MESSAGES.LEFT,
              addNotifications,
            });
            updateCurrentConversation("");
          } catch (e) {
            unexpectedErrorNotification(e.message, addNotifications);
          }
        }}
        conversation={props.convo}
        addNotifications={addNotifications}
      />

      <ActionErrorModal
        errorText={showError || ERROR_MODAL_MESSAGES.CHANGE_CONVERSATION_NAME}
        isOpened={!!showError}
        onClose={() => {
          setErrorToShow(false);
          setErrorData(undefined);
        }}
        error={errorData}
      />
      {props.isManageParticipantOpen && (
        <ManageParticipantsModal
          handleClose={handleParticipantClose}
          isModalOpen={props.isManageParticipantOpen}
          title={manageParticipants}
          participantsCount={props.participants.length}
          participantsList={props.participants}
          onClick={(content: Content) => {
            handleParticipantClose();
            switch (content) {
              case Content.AddChat:
                handleChatOpen();
                return null;
              default:
                return null;
            }
          }}
          onParticipantRemove={async (participant) => {
            await removeParticipant(
              sdkConvo,
              getSdkParticipantObject(participant),
              addNotifications
            );
          }}
          conversationSid={props.convo.sid} // Pass the current conversation SID
        />
      )}

    {props.isThredHistoryOpen && (
        <ManageThreadModal
          handleClose={handleThreadHistoryClose}
          isModalOpen={props.isThredHistoryOpen}
          title={threadHistory}
          conversationSid={props.convo.sid} // Pass the current conversation SID
        />
      )}
      
      {isAddChatOpen && (
        <AddChatParticipantModal
          names={names}
          setNames={setNames}
          errors={errors}
          isModalOpen={isAddChatOpen}
          title={manageParticipants}
          nameInputRef={nameInputRef}
          handleClose={() => {
            emptyData();
            handleChatClose();
          }}
          onBack={() => {
            emptyData();
            handleChatClose();
            props.setIsManageParticipantOpen(true);
          }}
          action={handleAddParticipants}
        />
      )}
    </>
  );
};

export default Settings;
