import React, { useEffect, useState } from "react";
import { Client, Conversation, Message } from "@twilio/conversations";
import { Box, Spinner } from "@twilio-paste/core";

interface MessagesViewProps {
  client: Client;
  conversation: Conversation;
}

const MessagesView: React.FC<MessagesViewProps> = ({ client, conversation }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const paginator = await conversation.getMessages();
        setMessages(paginator.items);
      } catch (error) {
        console.error("Error fetching messages:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, [conversation]);

  if (loading) {
    return <Spinner 
    title="Messages are loading"
    decorative={false} 
    />;
    
  }

  return (
    <Box style={{
      maxHeight: '400px',
      overflowY: 'auto',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '10px',
      backgroundColor: '#f9f9f9'
    }}>
      {messages.map((message, index) => (
        <Box key={index}
        style={{
          padding: '10px',
          marginBottom: '10px',
          borderRadius: '5px',
          backgroundColor: message.author === 'me' ? '#e0f7fa' : '#ffffff',
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
        }}>
          <strong>{message.author}</strong>: {message.body}
        </Box>
      ))}
    </Box>
  );
};

export default MessagesView;
