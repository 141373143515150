// Import All Necessary Libraries
import React, { useState } from "react";
import { Client } from "@twilio/conversations";
import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  List,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import CreateConversationButton from "./CreateConversationButton";
import ConversationsList from "./ConversationsList";
import { useDispatch, useSelector } from "react-redux";
import { filterConversations } from "./../../store/action-creators";
import { getTranslation } from "./../../utils/localUtils";
import AllConversationsButton from "./AllConversationsButton";

// Redux Related
import { bindActionCreators } from "redux";
import { AppState, actionCreators } from "../../store";


// Defines the props for the ConversationsContainer component.
interface ConvosContainerProps {
  client?: Client;
}

// The width we will use for the drawer
const drawerWidth = 240;

// Create the React Element
const ConversationsContainer: React.FC<ConvosContainerProps> = (props) => {

  const dispatch = useDispatch();
  // Get the display state, if it is set to mobile
  const isMobile = useSelector(
    (state: AppState) => state.isMobile
  );
  
  const local = useSelector((state: AppState) => state.local);
  const users = useSelector((state: AppState) => state.users);
  const currentUser = users[props.client?.user?.identity ?? ""];
  const userRole = currentUser?.role;

  const search = getTranslation(local, "convoSearch");

  // Function to handle the search
  const handleSearch = (searchString: string) => {
    dispatch(filterConversations(searchString));
  };
  const isDrawerOpen = useSelector(
    (state: AppState) => state.isDrawerOpen
  );
  const { updateDrawer } = bindActionCreators(actionCreators, dispatch);
  const handleDrawerToggle = () => {
    updateDrawer(!isDrawerOpen)
  }

  // Here we have the component for a the Drawer, a MUI component that opens and closes
  // like a sidebar, with its functionalities.
  const drawer = (
    <div>
      <Box sx={{ p: 2 }}>
        {userRole === "G4" && (
          <CreateConversationButton client={props.client} collapsed={isMobile} />
        )}
        {userRole === "G4" && !isMobile && (
          <div style={{ marginTop: "10px" }}>
            <AllConversationsButton client={props.client} collapsed={isMobile}/>
          </div>
        )}
        {/* {userRole === "G4" && mobileOpen && (
          <div style={{ marginTop: "10px" }}>
            <AllConversationsButton client={props.client} collapsed={matches} />
          </div>
        )} */}
        <Box display="flex" alignItems="center" marginTop="space60">
          <InputBase
            placeholder={search}
            onChange={(e) => handleSearch(e.target.value)}
            sx={{ width: "100%", mt: 2 }}
          />
          {isDrawerOpen && (
            <IconButton
              color="inherit"
              aria-label="close drawer"
              edge="end"
              onClick={handleDrawerToggle}
            >
              <CloseIcon />
            </IconButton>
            
          )}
          
        </Box>
        
      </Box>
      <Divider />
      <List>
        <ConversationsList />
      </List>
    </div>
  ) 

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        <Drawer
          variant="temporary"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }} // Better open performance on mobile.
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      </Box>
    </Box>
  );
};

export default ConversationsContainer;
