// Import Redux Related
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators, AppState } from "../../store";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { ReduxMessage } from "../../store/reducers/messageListReducer";

// Import React Related
import { ChangeEvent, useEffect, useMemo, useState } from "react";

// Import Twilio Related
import { Client } from "@twilio/conversations";
import { AttachIcon } from "@twilio-paste/icons/esm/AttachIcon";
import { Box, Button } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";
import { Text } from "@twilio-paste/text";

// Import Constants and Helpers
import { MAX_FILE_SIZE } from "../../constants";
import { getTypingMessage, unexpectedErrorNotification } from "../../helpers";

// Import Custom Components

import MessageInput from "./MessageInput";
import SendMessageButton from "./SendMessageButton";
import { getSdkConversationObject } from "../../conversations-objects";
import Thinking from "./Thinking"; // To better visualize Alex Thinking
import JSZip from "jszip";

interface SendMessageProps {
  convoSid: string;
  client: Client;
  messages: ReduxMessage[];
  convo: ReduxConversation;
  typingData: string[];
  droppedFiles: File[];
}

const MessageInputField: React.FC<SendMessageProps> = (
  props: SendMessageProps
) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  // needed to clear input type=file
  const [filesInputKey, setFilesInputKey] = useState<string>("input-key");
  const theme = useTheme();
  const typingInfo = getTypingMessage(props.typingData);

  const dispatch = useDispatch();
  const { addNotifications } = bindActionCreators(actionCreators, dispatch);
  const { updateAlexThinking } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    setMessage("");
    setFiles([]);
    setFilesInputKey(Date.now().toString());
  }, [props.convo.sid]);

  useEffect(() => {
    if (!files.length) {
      setFilesInputKey(Date.now().toString());
    }
  }, [files]);

  useEffect(() => {
    if (props.droppedFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...props.droppedFiles]);
    }
  }, [props.droppedFiles]);
  //previous code
  // useEffect(() => {
  //   const abortController = new AbortController();
  //   setFiles(props.droppedFiles);
  //   return () => {
  //     abortController.abort();
  //   };
  // }, [props.droppedFiles]);

  const sdkConvo = useMemo(
    () => getSdkConversationObject(props.convo),
    [props.convo.sid]
  );

  const onFilesChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files: assets } = event.target;
    if (!assets?.length) {
      return;
    }

    const validFiles = Array.from(assets).filter(
      ({ size }) => size < MAX_FILE_SIZE + 1
    );

    if (validFiles.length < assets.length) {
      // TODO: show error
      return;
    }
    const newFiles = validFiles.filter(
      (newFile) =>
        !files.some(
          (existingFile) =>
            existingFile.name === newFile.name &&
            existingFile.size === newFile.size &&
            existingFile.lastModified === newFile.lastModified
        )
    );

    if (newFiles.length < validFiles.length) {
      // TODO: show error about duplicate files
    }

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const onFileRemove = (file: string) => {
    const fileIdentityArray = file.split("_");
    const fileIdentity = fileIdentityArray
      .slice(0, fileIdentityArray.length - 1)
      .join();
    const existentFiles = files.filter(
      ({ name, size }) =>
        name !== fileIdentity &&
        size !== Number(fileIdentityArray[fileIdentityArray.length - 1])
    );

    setFiles(existentFiles);
  };

  function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  const onMessageSend = async () => {
    if (message.trim().length === 0 && files.length === 0) {
      return;
    }

    const { convo } = props;
    const sdkConvo = getSdkConversationObject(convo);
    const newMessageBuilder = sdkConvo.prepareMessage().setBody(message);
    newMessageBuilder.setAttributes({ messageType: "text" });
    let filesArray = [];

    for (const file of files) {
      if (file.type === "application/zip") {
        try {
          const zip = await JSZip.loadAsync(file);
          const zipFiles = Object.keys(zip.files);

          for (const filename of zipFiles) {
            const zipFile = zip.files[filename];
            // Only process files, not directories or system-specific files
            if (
              !zipFile.dir &&
              !filename.startsWith("__MACOSX") &&
              !filename.endsWith(".DS_Store")
            ) {
              const base64 = await zipFile.async("base64");
              const content = await zipFile.async("uint8array");

              if (content.length > 0) {
                // Ignore zero size files
                filesArray.push({
                  sid: props.convoSid,
                  name: filename,
                  base64: `data:application/octet-stream;base64,${base64}`,
                  size: content.length,
                  type: "application/octet-stream",
                });
                // FILES ON THE UI ONE BY ONE
                // const fileData = new FormData();
                // fileData.set(filename, new Blob([content]), filename);
                // newMessageBuilder.addMedia(fileData);
              }
            }
          }
          const fileData = new FormData();
          fileData.set(file.name, file, file.name);
          newMessageBuilder.addMedia(fileData);
        } catch (error) {
          console.error("Error extracting ZIP file:", error);
          return;
        }
      } else {
        const fileData = new FormData();
        fileData.set(file.name, file, file.name);
        const base64 = await fileToBase64(file);

        filesArray.push({
          sid: props.convoSid,
          name: file.name,
          base64: base64,
          size: file.size,
          type: file.type,
        });

        newMessageBuilder.addMedia(fileData);
      }
    }

    setMessage("");
    setFiles([]);
    const messageIndex = await newMessageBuilder.build().send();

    try {
      await sdkConvo.advanceLastReadMessageIndex(messageIndex ?? 0);
      const messageObject = await sdkConvo.getMessages(1);
      updateAlexThinking(true);

      if (messageObject.items.length > 0) {
        const lastMessage = messageObject.items[0];
        const lastMessageState = (lastMessage as any).state;

        let messageToBeSentToAPI = {
          sid: lastMessageState.sid,
          body: lastMessageState.body,
          attributes: lastMessageState.attributes,
          author: lastMessageState.author,
          dateCreated: lastMessageState.dateCreated,
          index: lastMessageState.index,
          participantSid: lastMessageState.participantSid,
          medias: filesArray,
        };

        console.log(messageToBeSentToAPI, props.convoSid);

        let environment = localStorage.getItem("env") || "development";

        let urlToTrigger =
          environment === "production"
            ? "https://handleuimessagestwilio-prod.smartagent.workers.dev"
            : "https://handleuimessagestwilio-dev.smartagent.workers.dev";

        console.log(environment, urlToTrigger);

        const resp = await fetch(urlToTrigger, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Allow-Control-Allow-Origin": "*",
            environment: localStorage.getItem("env") || "development",
          },
          body: JSON.stringify({
            convoSid: props.convoSid,
            message: messageToBeSentToAPI,
            modifyingFlow: `twilioConversationApp_${environment}`,
          }),
        });

        const respData = await resp.json();
        console.log(respData);
      }
    } catch (e) {
      unexpectedErrorNotification(e.message, addNotifications);
      updateAlexThinking(false);
      throw e;
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexBasis="60px"
        flexGrow={10}
        flexDirection="column"
        borderTopStyle="solid"
        borderTopWidth="borderWidth10"
        style={{
          borderTopColor: theme.borderColors.colorBorderWeak,
          backgroundColor: theme.backgroundColors.colorBackgroundBody,
          position: "fixed",
          bottom: 0,
          width: "80vw",
        }}
      >
        <Box
          paddingBottom="space20"
          paddingTop="space50"
          paddingLeft="space150"
          hidden={!props.typingData.length}
        >
          <Text as="p" color="colorTextIcon">
            {typingInfo}
          </Text>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          height="100%"
          flexGrow={10}
          paddingBottom="space30"
          paddingTop="space40"
        >
          <Box
            paddingBottom="space30"
            paddingLeft="space50"
            paddingRight="space10"
            paddingTop="space20"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="start"
          >
            <Button variant="link">
              <label htmlFor="file-input">
                <AttachIcon
                  decorative={true}
                  title="Attach file"
                  size="sizeIcon50"
                />
              </label>
              <input
                id="file-input"
                key={filesInputKey}
                type="file"
                style={{ display: "none" }}
                onChange={onFilesChange}
              />
            </Button>
          </Box>
          <Box paddingRight="space50" flexGrow={10}>
            <MessageInput
              assets={files}
              message={message}
              onChange={(e: string) => {
                sdkConvo.typing();
                setMessage(e);
              }}
              onEnterKeyPress={async () => {
                await onMessageSend();
              }}
              onFileRemove={onFileRemove}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="start"
            // style={!matches?{

            //   width: '90vw',

            // }:{
            //     display: "flex",
            // }}
          >
            {message || files.length ? (
              <SendMessageButton message={message} onClick={onMessageSend} />
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MessageInputField;
