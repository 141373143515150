import {
  Anchor,
  Box,
  ModalBody,
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "@twilio-paste/core";
import { MenuButton, Menu, MenuItem, useMenuState } from "@twilio-paste/menu";
import { Text } from "@twilio-paste/text";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { Avatar } from "../Avatar";
import { UserIcon } from "@twilio-paste/icons/cjs/UserIcon";

import ConvoModal from "./ConvoModal";
import { Content } from "../../types";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import { AppState } from "../../store";
import { getTranslation } from "../../utils/localUtils";
import { useSelector } from "react-redux";
import { useState } from "react";
import { getConversationAttributes} from "../../api";
import { useEffect } from "react";

interface ManageThreadModalProps {
  handleClose: () => void;
  isModalOpen: boolean;
  title: string;
  conversationSid: string;

}




const ManageThreadModal: React.FC<ManageThreadModalProps> = (
  props: ManageThreadModalProps
) => {
  const menu = useMenuState({ placement: "bottom-start" });
  const [messagesList, setMessagesList] = useState([]);
  
  const local = useSelector((state: AppState) => state.local);
  const parseList = (list: any[]) => {
    return list.map((item) => {
      try {
        return JSON.parse(item);
      } catch (e) {
        return item;
      }
    });
  };
  useEffect(() => {
    const fetchConversationAttributes = async () => {
      try {
        let resFromAlex = await getConversationAttributes(props.conversationSid);
        let data = resFromAlex.data;
        console.log(data, 'fetched convos');

        data = parseList(data);
        console.log(data, 'parsed convos');
        setMessagesList(data);
        
      } catch (error) {
        console.error("Error fetching Thread status:", error);
      }
    };
    

    fetchConversationAttributes();
  }, []);


  return (
    <>
      <ConvoModal
        handleClose={() => props.handleClose()}
        isModalOpen={props.isModalOpen}
        title={props.title}
        modalBody={
          <ModalBody>
            
            <Box
              style={{
                marginTop: "12px",
                overflow: "hidden",
                overflowY: "auto",
                maxHeight: "500px",
              }}
            >
            
                {/* create a list and display all the messages */}
                <Box
                  as="ul"
                  style={{
                    listStyleType: "none",
                    padding: "0",
                  }}
                >
                  { messagesList && messagesList.map((messageObj: any, index: number) => (
                    <Box
                      as="li"
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                    >
                      {/* <Avatar
                        name={message.author}
                        size="sizeIcon70"
                        style={{ marginRight: "8px" }}
                      /> */}
                      <Box>
                        <Text as="p" fontSize="fontSize30" fontWeight="fontWeightBold">
                          {messageObj.login_user_name || 'federa'}
                        </Text>
                        <Text as="p" fontSize="fontSize20">
                          {messageObj.message || 'Empty message'}
                        </Text>
                      </Box>
                    </Box>
                  ))}
            </Box>
              
            </Box>
          </ModalBody>
        }
      />
    </>
  );
};

export default ManageThreadModal;
