// React Realted Imports
import { useEffect } from "react";
import { useMemo, useState } from "react";

// Redux Related Imports
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ReduxConversation } from "../../store/reducers/convoReducer";

import { actionCreators, AppState } from "../../store";

// Twilio Related Imports
import { Client } from "@twilio/conversations";
import { Box } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";

// Custom Components
import { getAlexAPI, setAlexState } from "../../api";
import ConversationDetails from "./ConversationDetails";
import MessagesBox from "../message/MessagesBox";
import MessageInputField from "../message/MessageInputField";
import styles from "../../styles";
import { getSdkConversationObject } from "../../conversations-objects";
import ActionErrorModal from "../modals/ActionErrorModal";

// Helpers and Constants
import { successNotification } from "../../helpers";
import { CONVERSATION_MESSAGES, ERROR_MODAL_MESSAGES } from "../../constants";
import { getTranslation } from "./../../utils/localUtils";

// Material UI Components
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import Chip from '@mui/material/Chip';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import Thinking from "../message/Thinking"; // To better visualize Alex Thinking
import { updateAlexThinking } from "../../store/action-creators";


interface ConvoContainerProps {
  conversation?: ReduxConversation;
  client?: Client;
}

const ConversationContainer: React.FC<ConvoContainerProps> = (
  props: ConvoContainerProps
) => {
  const theme = useTheme();

  const users = useSelector((state: AppState) => state.users);
  const currentUser = users[props.client?.user?.identity ?? ""]; 
  const userRole = currentUser?.role;

  const alexThinking = useSelector(
    (state: AppState) => state.alexThinking
  );

  const sid = useSelector((state: AppState) => state.sid);
  const loadingStatus = useSelector((state: AppState) => state.loadingStatus);
  const participants =
    useSelector((state: AppState) => state.participants)[sid] ?? [];
  const messages = useSelector((state: AppState) => state.messages);
  const typingData =
    useSelector((state: AppState) => state.typingData)[sid] ?? [];
  const lastReadIndex = useSelector((state: AppState) => state.lastReadIndex);
  const use24hTimeFormat = useSelector(
    (state: AppState) => state.use24hTimeFormat
  );
  const local = useSelector((state: AppState) => state.local);
  const greeting = getTranslation(local, "greeting");

  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);

  const dispatch = useDispatch();
  
  const { pushMessages, updateConversation, addNotifications } =
    bindActionCreators(actionCreators, dispatch);
    
    const { updateAlexSleeping } = bindActionCreators(actionCreators, dispatch);
    const alexSleeping = useSelector(
      (state: AppState) => state.alexSleeping
    );
    useEffect(() => {
      const fetchAlexStatus = async () => {
        try {
          let resFromAlex = await getAlexAPI(sid);
          // If we have response from Alex we state the redux state to true, if not to false
          updateAlexSleeping(resFromAlex.data.aiAsleep === "true" ? true : false);
        } catch (error) {
          console.error("Error fetching Alex's status:", error);
        }
      };
      
      fetchAlexStatus();
    }, [sid, alexSleeping]);
    
    // useEffect(() => {
    //   updateAlexThinking(false);
    // }, [props.client]);

    const isMobile = useSelector(
      (state: AppState) => state.isMobile
    );

  const [showError, setErrorToShow] = useState<
    | {
        title: string;
        description: string;
      }
    | false
  >();
  const [errorData, setErrorData] = useState<
    | {
        message: string;
        code: number;
      }
    | undefined
  >();

  const sdkConvo = useMemo(() => {
    if (props.conversation) {
      const conversation = getSdkConversationObject(props.conversation);
      if (conversation) {
        return conversation;
      }
    }
    return;
  }, [props.conversation?.sid]);

  const handleDroppedFiles = (droppedFiles: File[]) => {
    setDroppedFiles(droppedFiles);
  };


  return (
    <Box style={styles.convosWrapperBox}>
      <ActionErrorModal
        errorText={showError || ERROR_MODAL_MESSAGES.CHANGE_CONVERSATION_NAME}
        isOpened={!!showError}
        onClose={() => {
          setErrorToShow(false);
          setErrorData(undefined);
        }}
        error={errorData}
      />
      {sid && props.conversation && props.client ? (
        <div>
          <ConversationDetails
          convoSid={sid}
          convo={props.conversation}
          userRole={userRole}
          participants={participants}
          updateConvoName={(val: string) => {
            sdkConvo
              ?.updateFriendlyName(val)
              .then((convo) => {
                updateConversation(convo.sid, convo);
                successNotification({
                  message: CONVERSATION_MESSAGES.NAME_CHANGED,
                  addNotifications,
                });
              })
              .catch((e) => {
                setErrorData(e);
                setErrorToShow(ERROR_MODAL_MESSAGES.CHANGE_CONVERSATION_NAME);
              });
          }}
        />
          <MessagesBox
            currentUserIdentity={currentUser?.identity}
            key={sid}
            convoSid={sid}
            convo={props.conversation}
            upsertMessage={pushMessages}
            client={props.client}
            messages={messages[sid]}
            loadingState={loadingStatus}
            participants={participants}
            lastReadIndex={lastReadIndex}
            use24hTimeFormat={use24hTimeFormat}
            handleDroppedFiles={handleDroppedFiles}
          />
          
          {alexSleeping ? (
          <Chip icon={<NotificationsPausedIcon />} label={!isMobile ?"Alex is sleeping": "Sleeping"} />
        ) : (
          <Chip color="success" icon={<OnlinePredictionIcon />} label={!isMobile ? "Alex is online" : "Online"} />
        )}
         <div>
         {/* {(alexThinking && !alexSleeping) ? <Thinking /> : null} */}
        
        <MessageInputField
          convoSid={sid}
          client={props.client}
          messages={messages[sid]}
          convo={props.conversation}
          typingData={typingData}
          droppedFiles={droppedFiles}
        />
      </div>
        </div>
      ) : (
        <Box
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          fontSize: theme.fontSizes.fontSize30,
          fontWeight: theme.fontWeights.fontWeightNormal,
          lineHeight: "20px",
          color: theme.textColors.colorTextIcon,
        }}
      >
        {greeting}
      </Box>
      )}
    </Box>
  );
};

export default ConversationContainer;
