import { User } from "@twilio/conversations";
import { ActionType } from "../action-types";
import { Action } from "../actions";
import { usersMap } from "../../conversations-objects";

export type ReduxUser = {
  identity: string;
  friendlyName: string;
  role: string; // Add the role property
};

export type UsersState = {
  [identity: string]: ReduxUser;
};

const initialState: UsersState = {};

type UserAttributes = {
  userType: string;
  [key: string]: any;
};

const reduxifyUser = (user: User): ReduxUser => {
  const attributes = user.attributes as UserAttributes;
  return {
    identity: user.identity,
    friendlyName: user.friendlyName ?? "",
    role: attributes.userType ?? "",
  };
};

const reducer = (
  state: UsersState = initialState,
  action: Action
): UsersState => {
  switch (action.type) {
    case ActionType.UPDATE_USER:
      const user = action.payload;
      usersMap.set(user.identity, user);

      return {
        ...state,
        [user.identity]: reduxifyUser(user),
      };
    default:
      return state;
  }
};

export default reducer;
