import { LogoTwilioIcon } from "@twilio-paste/icons/esm/LogoTwilioIcon";
import React from "react";
import { IconSize, TextColor } from "@twilio-paste/style-props";

export const LOGO_TITLE = "Federa Conversations";
export const LOGO_SUB_TITLE = "";
export const APP_TITLE = "Federa Conversations";

interface LogoTwilioIconProps {
  decorative?: boolean;
  color?: TextColor | undefined;
  size?: IconSize | undefined;
  title?: string;
}

export const AppLogo: React.FC<LogoTwilioIconProps> = ({
  decorative = true,
  color = "colorTextBrandHighlight",
  size = "sizeIcon40",
  title = "app logo",
}) => (
  <>
    <img src="./" alt="Federa Logo"/>
  </>
  // <LogoTwilioIcon
  //   decorative={true}
  //   color={color}
  //   size={size}
  //   title={title}
  // />
);
