// The aim of this reducer is to set the state of Display to a mobile or not
import { ActionType } from "../action-types";
import { Action } from "../actions";

const reducer = (state = true, action: Action): boolean => {
  switch (action.type) {
    case ActionType.UPDATE_DISPLAY:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
