import React, { useState } from 'react';
import CallIcon from '@mui/icons-material/Call';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Chip } from '@mui/material';

const PhoneCallComponent = () => {
    const phoneNumber = '+12028758273';
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCall = () => {
    setOpen(false);
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div>

      
      <Chip  onClick={handleClickOpen}  style={{padding:"0px 5px", backgroundColor:"#0b6836", color:"white"}} icon={<CallIcon fontSize="small" style={{ cursor: 'pointer', color:"white", backgroundColor:"#0b6836" }} />}/>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{textAlign:"center"}} id="alert-dialog-title">{"Confirm Call"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to call our customer support?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{justifyContent:"space-evenly"}}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCall} color="success">
            Call
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PhoneCallComponent;
