import { useState, useEffect } from "react";
import { Button } from "@twilio-paste/button";
import { Box } from "@twilio-paste/core";
import { ProductConversationsIcon } from "@twilio-paste/icons/esm/ProductConversationsIcon";
import CryptoJS from 'crypto-js';

import { getToken } from "../../api";
import { InputType } from "../../types";
import ModalInputField from "../modals/ModalInputField";
import styles from "../../styles";
import TwilioLogo from "../icons/TwilioLogo";
import useAppAlert from "../../hooks/useAppAlerts";
import React from "react";

type SetTokenType = (token: string) => void;

interface LoginProps {
  setToken: SetTokenType;
}

async function login(
  username: string,
  password: string,
  env: string,
  setToken: (token: string) => void
): Promise<string> {
  try {
    const token = await getToken(username.trim(), password, env);
    if (token === "") {
      return "Received an empty token from backend.";
    }

    localStorage.setItem("username", username);
    localStorage.setItem("password", password);
    setToken(token);

    return "";
  } catch (error) {
    let message = "Unknown Error";
    if (error instanceof Error) {
      message = error.message;
    } else {
      message = String(error);
    }
    return message;
  }
}

const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const [isFormDirty, setFormDirty] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("123");
  const [, AlertsView] = useAppAlert();
  let usernameHere;
  let env;

  const secretKey = "MAsQ5IsztgJWTWKsi9MBOQ+0djYl2MuJlsVPhSNMcFrrLfVA7CMhu3H8ulBiEr3G"; // Replace with your actual secret key

  useEffect(() => {
    const url = new URL(window.location.href);
    const encryptedData = url.searchParams.get("data");
    const decodedData = encryptedData ? atob(encryptedData) : '';
    
    if (decodedData) {
      try {
        const params = decodedData.split("&");

        const paramMap: { [key: string]: string } = params.reduce((acc: { [key: string]: string }, param: string) => {
          const [key, value] = param.split("=");
          acc[key] = value;
          return acc;
        }, {});
        
        // Extract the values for 'env' and 'phone'
        const env = paramMap.env;
        const usernameHere = paramMap.phone;
        const paramKey = paramMap.key;
        if (paramKey !== secretKey) {
          console.error("Invalid key");
          return;
        }

        localStorage.setItem("env", env.toString());
        handleLogin(usernameHere.toString(), env.toString());
      } catch (error) {
        console.error("Decryption failed:", error);
      }
    }
  }, [secretKey]);

  
  const handleLogin = async (usernameHere: string, env: string) => {
    const error = await login(usernameHere, password, env, props.setToken);
    if (error) {
      setFormError('Invalid username or password. Please try again.');
    }
  };

  // useEffect(() => {
  //   const abortController = new AbortController();
  //   const handleKeyPress = (event: KeyboardEvent) => {
  //     if (event.key === "Enter") {
  //       handleLogin();
  //     }
  //   };

  //   document.addEventListener("keydown", handleKeyPress);

  //   return function cleanup() {
  //     document.removeEventListener("keydown", handleKeyPress);
  //     abortController.abort();
  //   };
  // }, [password, username]);

  return (
    <Box style={styles.loginContainer}>
      <AlertsView />
      <Box style={styles.loginContent}>
        <Box>
          <ProductConversationsIcon
            decorative={true}
            size="sizeIcon90"
            color="colorTextInverse"
          />
        </Box>
        <div style={styles.loginTitle}>Federa Conversations</div>
        <Box style={styles.loginForm}>
          {/* <Box style={styles.userInput}>
            <ModalInputField
              label="Username"
              placeholder=""
              isFocused={true}
              error={
                isFormDirty && !username.trim()
                  ? "Enter a username to sign in."
                  : formError ?? ""
              }
              input={username}
              onChange={(username: string) => {
                setUsername(username);
                setFormError("");
              }}
              onBlur={() => {
                if (password) {
                  setFormDirty(true);
                }
              }}
              id="username"
            />
          </Box> */}
          {/* <Box style={styles.passwordInput}>
            <ModalInputField
              label="Password"
              placeholder=""
              error={
                isFormDirty && !password
                  ? "Enter a password to sign in."
                  : formError ?? ""
              }
              input={password}
              onChange={(password: string) => {
                setPassword(password);
                setFormError("");
              }}
              onBlur={() => setFormDirty(true)}
              inputType={showPassword ? InputType.Text : InputType.Password}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              id="password"
            />
          </Box> */}
          {/* <Box style={styles.loginButton}>
            <Button
              fullWidth
              disabled={!username || !password}
              variant="primary"
              onClick={handleLogin}
              id="login"
            >
              Sign in
            </Button>
          </Box> */}
        </Box>
        {/* <Box style={{ paddingTop: 40 }}>
          <TwilioLogo />
        </Box> */}
      </Box>
      <Box style={styles.loginBackground}>
        <Box
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#0B6836",
            transform: "skewY(-12deg)",
            transformOrigin: "top right",
          }}
        />
      </Box>
    </Box>
  );
};

export default Login;
