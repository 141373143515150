import React, { useEffect, useState, useCallback, memo } from "react";
import { Client, Conversation, Participant } from "@twilio/conversations";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  Spinner,
  Box,
  Text,
} from "@twilio-paste/core";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import MessagesView from "../message/MessageView";
import { unexpectedErrorNotification } from "../../helpers";
import { getAllConversationsAlex } from "../../api";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Pagination from "@mui/material/Pagination";


interface AllConversationsModalProps {
  client?: Client;
  isOpen: boolean;
  onClose: () => void;
  currentUserIdentity: string;
}

const ConversationItem = memo(
  ({
    convo,
    selectedConvo,
    handleConvoClick,
    isUserParticipant,
    handleAddMeAsParticipant,
    client,
  }: {
    convo: Conversation;
    selectedConvo: string | null;
    handleConvoClick: (convoSid: string) => void;
    isUserParticipant: boolean;
    handleAddMeAsParticipant: () => void;
    client: Client | undefined;
  }) => (
    <Accordion
      expanded={selectedConvo === convo.sid}
      onChange={() => handleConvoClick(convo.sid)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${convo.sid}-content`}
        id={`${convo.sid}-header`}
      >
        <Typography variant="subtitle1">
          {convo.friendlyName ?? convo.sid}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box marginTop="space20">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {!isUserParticipant && (
              <Button variant="primary" onClick={handleAddMeAsParticipant}>
                Add Me as Participant
              </Button>
            )}
          </div>
          <MessagesView client={client!} conversation={convo} />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
);

const AllConversationsModal: React.FC<AllConversationsModalProps> = ({
  client,
  isOpen,
  onClose,
  currentUserIdentity,
}) => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [filteredConversations, setFilteredConversations] = useState<
    Conversation[]
  >([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedConvo, setSelectedConvo] = useState<string | null>(null);
  const [participantsMap, setParticipantsMap] = useState<
    Record<string, Participant[]>
  >({});
  const [isUserParticipant, setIsUserParticipant] = useState(false);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 20;

  const dispatch = useDispatch();
  const { updateCurrentConversation, updateParticipants } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const fetchConversations = useCallback(
    async (page: number) => {
      if (!client) return;

      try {
        setLoading(true);

        const response = await getAllConversationsAlex(page);
        const meta = response.meta;
        console.log("META", meta);

        const convoDetailsPromises = response.conversations.map(
          async (convoData: any) => {
            try {
              const convo = await client.peekConversationBySid(
                convoData.conversation_sid
              );
              return convo;
            } catch (error) {
              console.error(
                `Failed to fetch conversation ${convoData.conversation_sid}:`,
                error
              );
              return null;
            }
          }
        );

        const convoDetails = await Promise.all(convoDetailsPromises);
        const validConversations = convoDetails.filter(
          (convo) => convo !== null
        ) as Conversation[];

        setConversations(validConversations);
        setFilteredConversations(validConversations);

        // Update the total pages
        setTotalPages(Math.ceil(response.meta.total / 20) || totalPages);
      } catch (error: any) {
        console.error("Error fetching conversations:", error);
        unexpectedErrorNotification(error.message);
      } finally {
        setLoading(false);
      }
    },
    [client, pageSize, totalPages]
  );

  useEffect(() => {
    if (isOpen) {
      fetchConversations(currentPage);
    }
  }, [isOpen, currentPage, fetchConversations]);

  useEffect(() => {
    const results = conversations.filter(
      (convo) =>
        convo.friendlyName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        convo.sid.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredConversations(results);
  }, [searchTerm, conversations]);

  const handleConvoClick = async (convoSid: string) => {
    console.log(`Attempting to fetch conversation with SID: ${convoSid}`);

    if (selectedConvo === convoSid) {
      setSelectedConvo(null);
      return;
    }

    try {
      const convo = await client?.peekConversationBySid(convoSid);
      console.log("Fetched conversation:", convo);

      if (!convo) {
        throw new Error(`Conversation with SID ${convoSid} not found`);
      }

      setSelectedConvo(convoSid);
      updateCurrentConversation(convo.sid);

      if (!participantsMap[convoSid]) {
        try {
          const participants = await convo.getParticipants();
          updateParticipants(participants, convo.sid);
          setParticipantsMap((prev) => ({
            ...prev,
            [convo.sid]: participants,
          }));

          const userIsParticipant = participants.some(
            (participant: Participant) =>
              participant.identity === currentUserIdentity
          );
          setIsUserParticipant(userIsParticipant);
        } catch (participantsError: any) {
          console.error(
            `Error fetching participants for conversation ${convoSid}:`,
            participantsError
          );
          unexpectedErrorNotification(
            `Error fetching participants for conversation ${convoSid}: ${participantsError.message}`
          );
          updateParticipants([], convo.sid);
        }
      } else {
        const userIsParticipant = participantsMap[convoSid].some(
          (participant: Participant) =>
            participant.identity === currentUserIdentity
        );
        setIsUserParticipant(userIsParticipant);
      }
    } catch (error: any) {
      console.error(`Error fetching conversation ${convoSid}:`, error);
      unexpectedErrorNotification(
        `Error accessing conversation ${convoSid}: ${error.message}`
      );
      setSelectedConvo(null);
    }
  };

  const handleAddMeAsParticipant = async () => {
    if (selectedConvo && client) {
      try {
        const convo = await client.peekConversationBySid(selectedConvo);
        await convo.add(currentUserIdentity);
        const participants = await convo.getParticipants();
        updateParticipants(participants, convo.sid);
        setParticipantsMap((prev) => ({
          ...prev,
          [convo.sid]: participants,
        }));
        setIsUserParticipant(true);
        console.log(
          `Added user ${currentUserIdentity} as a participant to the conversation`
        );
      } catch (error: any) {
        console.error("Failed to add user as a participant:", error);
        unexpectedErrorNotification("Failed to add yourself as a participant");
      }
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onClose}
      size="default"
      ariaLabelledby="all-conversations-modal"
    >
      <ModalHeader id="all-conversations-modal">
        All Conversations with Alex
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            textAlign="center"
            alignItems="center"
            height="100%"
          >
            <Spinner title="Conversations are loading" decorative={false} />
          </Box>
        ) : (
          <>
            <div style={{ padding: "5px 0px" }}>
              <Input
                type="text"
                placeholder="Search conversations..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {filteredConversations.length > 0 ? (
              filteredConversations.map((convo) => (
                <ConversationItem
                  key={convo.sid}
                  convo={convo}
                  selectedConvo={selectedConvo}
                  handleConvoClick={handleConvoClick}
                  isUserParticipant={isUserParticipant}
                  handleAddMeAsParticipant={handleAddMeAsParticipant}
                  client={client}
                />
              ))
            ) : (
              <Text as="span">No conversations found.</Text>
            )}
            <Box display="flex" justifyContent="center" marginTop="space50">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                showFirstButton
                showLastButton
              />
            </Box>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default AllConversationsModal;
