import { Avatar } from "./Avatar";
import { Text } from "@twilio-paste/core";
import { Menu, MenuButton, useMenuState, MenuItem } from "@twilio-paste/menu";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import React, { useMemo, useState } from "react";
import styles from "../styles";
import { Client, ConnectionState, User } from "@twilio/conversations";
import UserProfileModal from "./modals/UserProfileModal";
import { readUserProfile } from "../api";
import { getTranslation } from "./../utils/localUtils";
import { IconButton, Box, Divider, List, InputBase } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

// Redux Related
import { useDispatch, useSelector } from "react-redux";
import { actionCreators, AppState } from "../store";
import { bindActionCreators } from "redux";

// Custom Components
import ConversationsList from "./conversations/ConversationsList";
import CreateConversationButton from "./conversations/CreateConversationButton";

type AppHeaderProps = {
  user: string;
  onSignOut: () => void;
  connectionState: ConnectionState;
  client?: Client;
};
const AppHeader: React.FC<AppHeaderProps> = ({
  user,
  onSignOut,
  connectionState,
  client,
}) => {
  const menu = useMenuState();

  const [showUserProfileModal, setUserProfileModal] = useState(false);

  const [userProfile, setUserProfile] = useState<User | undefined>(undefined);

  const dispatch = useDispatch();


  const { updateDrawer } = bindActionCreators(actionCreators, dispatch);
  const isDrawerOpen = useSelector(
    (state: AppState) => state.isDrawerOpen
  );

  const handleUserProfileModalClose = () => setUserProfileModal(false);
  const local = useSelector((state: AppState) => state.local);
  const online = getTranslation(local, "online");
  const connecting = getTranslation(local, "connecting");
  const offline = getTranslation(local, "offline");
  const signout = getTranslation(local, "signout");
  const userProfileTxt = getTranslation(local, "userProfileTxt");

  const label: "online" | "connecting" | "offline" = useMemo(() => {
    switch (connectionState) {
      case "connected":
        return "online";
      case "connecting":
        return "connecting";
      default:
        return "offline";
    }
  }, [connectionState]);

  const handleUserProfileModalOpen = async () => {
    const userProfileTemp = await readUserProfile(user, client);
    setUserProfile(userProfileTemp);
    setUserProfileModal(true);
  };

  return (
    <>
    <nav style={styles.appHeader}>
      <div style={styles.flex}>
        {/* <div style={styles.appLogoTitle}>
          {LOGO_TITLE}
        </div> */}
      </div>
      <div style={styles.appHeaderTile}>
        <div style={styles.userTile}>
          <div style={{display: "flex", alignItems: "center",}}>
            <Avatar name={user} />
            <div
              style={{
                padding: "0 10px",
              }}
            >
              {/* Disabled the User Info */}
              {/* <Text as="span" style={styles.userName}>
                {user}
              </Text> */}
              <Text
                as="span"
                color={
                  label === "online"
                    ? "colorTextPrimaryWeak"
                    : label === "connecting"
                    ? "colorTextIconBusy"
                    : "colorTextWeaker"
                }
                style={styles.userStatus}
              >
                {label === "online"
                  ? online
                  : label === "connecting"
                  ? `${connecting}...`
                  : offline}
              </Text>
            </div>

            {/* A DO NA DUHET ???? */}
            <MenuButton {...menu} variant="link" size="reset">
              <ChevronDownIcon
                color="colorTextInverse"
                decorative={false}
                title="Settings"
                />
            </MenuButton>
          </div>
          <Menu {...menu} aria-label="Preferences">
            <MenuItem {...menu} onClick={handleUserProfileModalOpen}>
            </MenuItem>
          </Menu>
          <div style={{justifySelf: "flex-end"}}>
          {!isDrawerOpen ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={()=>updateDrawer(!isDrawerOpen)}
                sx={{ position: "fixed", top: 16, right: 16 }}
              >
                <MenuIcon/>
            </IconButton>
            )
            :
            null}
          </div>
        </div>
        
      </div>
      {/* {showUserProfileModal && (
        <UserProfileModal
          isModalOpen={showUserProfileModal}
          handleClose={handleUserProfileModalClose}
          user={userProfile}
        ></UserProfileModal>
      )} */}
    </nav>
    </>
  );
};


{/* <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1, backgroundColor: "#0c6836"}}>
        <Toolbar sx={{
            display: { lg: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Conversations
          </Typography>
          
        </Toolbar>
      </AppBar> */}

export default AppHeader;
