import React, { RefObject, useEffect, useState } from "react";
import { ModalBody, Box } from "@twilio-paste/core";
import ModalInputField from "./ModalInputField";
import AddParticipantFooter from "./addParticipantFooter";
import { ActionName } from "../../types";
import ConvoModal from "./ConvoModal";
import { AppState } from "../../store";
import { getTranslation } from "./../../utils/localUtils";
import { useSelector } from "react-redux";
import { getAllUsers } from "../../api";
import { Autocomplete, TextField } from "@mui/material";

interface AddChatParticipantModalProps {
  names: string[];
  setNames: (names: string[]) => void;
  errors: string[];
  nameInputRef: RefObject<HTMLInputElement>;
  onBack: () => void;
  action: () => void;
  handleClose: () => void;
  isModalOpen: boolean;
  title: string;
}

type User = {
  friendly_name: string;
  identity: string;
}

const AddChatParticipantModal: React.FC<AddChatParticipantModalProps> = (
  props: AddChatParticipantModalProps
) => {
  const local = useSelector((state: AppState) => state.local);
  const addChatParticipant = getTranslation(local, "addChatParticipant");
  const userIdentity = getTranslation(local, "userIdentity");
  const userIdentityHelperTxt = getTranslation(local, "userIdentityHelperTxt");

  const [users, setUsers] = useState<User[]>([]);
  const [inputValues, setInputValues] = useState<string[]>(props.names);

  useEffect(() => {
    // Fetch users from Twilio Conversations API
    const fetchUsers = async () => {
      try {
        const response = await getAllUsers();
        let users = response.data.users;
        setUsers(users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleInputChange = (event: React.ChangeEvent<{}>, values: string[]) => {
    setInputValues(values);
    const names = values.map((value) => {
      let phoneNumber = value;
      if (value.includes("Phone Number: ")) {
        phoneNumber = value.split("Phone Number: ")[1];
      }
      return phoneNumber;
    });
    props.setNames(names);
  };

  return (
    <ConvoModal
      handleClose={() => props.handleClose()}
      isModalOpen={props.isModalOpen}
      title={props.title}
      modalBody={
        <ModalBody>
          <h3>{addChatParticipant}</h3>
          <Box
            as="form"
            onKeyPress={async (e) => {
              if (e.key === "Enter") {
                if (props.action) {
                  e.preventDefault();
                  props.action();
                }
              }
            }}
          >
            <Autocomplete
              multiple
              freeSolo
              filterSelectedOptions
              options={users.map(user => `Username: ${user.friendly_name}, Phone Number: ${user.identity}`)}
              getOptionLabel={(option) => option}
              value={inputValues}
              onChange={handleInputChange}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label={userIdentity}
                  placeholder="exampleusername"
                  error={!!props.errors.length}
                  helperText={props.errors.length ? props.errors[0] : userIdentityHelperTxt}
                  inputRef={props.nameInputRef}
                />
              )}
            />
          </Box>
        </ModalBody>
      }
      modalFooter={
        <AddParticipantFooter
          isSaveDisabled={!props.names.length || !!props.errors.length}
          actionName={ActionName.Save}
          onBack={() => {
            props.onBack();
          }}
          action={props.action}
        />
      }
    />
  );
};

export default AddChatParticipantModal;
