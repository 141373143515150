import {
  Anchor,
  Box,
  ModalBody,
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "@twilio-paste/core";
import { MenuButton, Menu, MenuItem, useMenuState } from "@twilio-paste/menu";
import { Text } from "@twilio-paste/text";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { Avatar } from "../Avatar";
import { UserIcon } from "@twilio-paste/icons/cjs/UserIcon";

// Material Ui Related Imports
import Button from '@mui/material/Button';

// Redux Related Imports
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { AppState, actionCreators } from "../../store";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";

import ConvoModal from "./ConvoModal";
import { Content } from "../../types";
import { getTranslation } from "./../../utils/localUtils";
import { useState } from "react";
import { getAlexAPI, setAlexState } from "../../api";
import { useEffect } from "react";

interface ManageParticipantsModalProps {
  participantsCount: number;
  handleClose: () => void;
  isModalOpen: boolean;
  title: string;
  onClick: (content: Content) => void;
  participantsList: ReduxParticipant[];
  onParticipantRemove: (participant: ReduxParticipant) => void;
  conversationSid: string; // Add the conversationSid prop
}




const ManageParticipantsModal: React.FC<ManageParticipantsModalProps> = (
  props: ManageParticipantsModalProps
) => {
  const menu = useMenuState({ placement: "bottom-start" });
  // const [isAlexSleeping, setIsAlexSleeping] = useState(false);
  const dispatch = useDispatch();
  const { updateAlexSleeping } = bindActionCreators(actionCreators, dispatch);
  // Here we import the actual Redux State
  const alexSleeping = useSelector(
    (state: AppState) => state.alexSleeping
  );
  
  const local = useSelector((state: AppState) => state.local);
  const participants = getTranslation(local, "participants");
  const addParticipant = getTranslation(local, "addParticipant");
  const smsParticipant = getTranslation(local, "smsParticipant");
  const whatsAppParticipant = getTranslation(local, "whatsAppParticipant");
  const chatParticipant = getTranslation(local, "chatParticipant");
  const remove = getTranslation(local, "remove");
  const sleepAlex = getTranslation(local, "sleepAlex");
  const wakeAlex = getTranslation(local, "wakeAlex");


  useEffect(() => {
    const fetchAlexStatus = async () => {
      try {
        let resFromAlex = await getAlexAPI(props.conversationSid);
        updateAlexSleeping(resFromAlex.data.aiAsleep === "true" ? true : false);
      } catch (error) {
        console.error("Error fetching Alex's status:", error);
      }
    };

    fetchAlexStatus();
  }, [props.conversationSid]);


  const handleAlexSleep = (sid: string) => {
    updateAlexSleeping(!alexSleeping);
    setAlexState(props.conversationSid);
  }
  return (
    <>
      <ConvoModal
        handleClose={() => props.handleClose()}
        isModalOpen={props.isModalOpen}
        title={props.title}
        modalBody={
          <ModalBody>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "6px",
              }}
            >
              <Box
                fontFamily="fontFamilyText"
                fontWeight="fontWeightBold"
                fontSize="fontSize30"
                lineHeight="lineHeight60"
              >
                {participants} ({props.participantsCount})
              </Box>
              <MenuButton {...menu} variant="secondary">
                {addParticipant}{" "}
                <ChevronDownIcon decorative size="sizeIcon10" />
              </MenuButton>
              <Menu {...menu} aria-label="Preferences">
                {/* Add participants */}
                {/* <MenuItem
                  {...menu}
                  onClick={() => props.onClick(Content.AddSMS)}
                >
                  {smsParticipant}
                </MenuItem>
                <MenuItem
                  {...menu}
                  onClick={() => {
                    props.onClick(Content.AddWhatsApp);
                  }}
                >
                  {whatsAppParticipant}
                </MenuItem> */}
                <MenuItem
                  {...menu}
                  onClick={() => {
                    props.onClick(Content.AddChat);
                  }}
                >
                  {chatParticipant}
                </MenuItem>
              </Menu>
            </Box>
            <Box
              style={{
                marginTop: "12px",
                overflow: "hidden",
                overflowY: "auto",
                maxHeight: "500px",
              }}
            >
              <Table>
                <THead hidden={true}>
                  <Tr>
                    <Th width="size10" />
                    <Th width="size40" textAlign="left" />
                    <Th textAlign="right" />
                  </Tr>
                </THead>
                <TBody>
                  {props.participantsList.length ? (
                    props.participantsList.map((user) => (
                      <Tr key={user.sid}>
                        <Td width="size20">
                          <Avatar
                            size="sizeIcon80"
                            name={
                              (user.identity ||
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                user.attributes["friendlyName"]) ??
                              "unknown"
                            }
                          />
                        </Td>
                        <Td textAlign="left">
                          <Text as="span" textAlign="left">
                            {user.type == "chat"
                              ? user.identity
                              : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              (user.attributes["friendlyName"] as string) ??
                              "unknown"}
                          </Text>
                        </Td>
                        {user.identity === "alex" ? (

                          <Td textAlign="right">
                            (
                              <Button
                                onClick={() => handleAlexSleep("CH03747f29caab40f48dbe3c54b22f03d5")}
                              >
                                {alexSleeping ? wakeAlex : sleepAlex}

                              </Button>
                            ) 
                          </Td>) : (
                          <Td textAlign="right">
                            {user.identity !==
                              localStorage.getItem("username") ? (
                              <Button
                                onClick={() => props.onParticipantRemove(user)}
                              >
                                {remove}
                              </Button>
                            ) : null}
                          </Td>)
                        }


                      </Tr>
                    ))
                  ) : (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "400px",
                      }}
                    >
                      <Box
                        style={{
                          color: "#606B85",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "12px",
                          }}
                        >
                          <UserIcon
                            decorative={false}
                            title="No participants"
                            size="sizeIcon40"
                            color="colorTextDecorative10"
                          />
                        </Box>
                        <Text
                          as="p"
                          fontSize="fontSize40"
                          style={{
                            color: "#606B85",
                          }}
                        >
                          No participants
                        </Text>
                      </Box>
                    </Box>
                  )}
                </TBody>
              </Table>
            </Box>
          </ModalBody>
        }
      />
    </>
  );
};

export default ManageParticipantsModal;
